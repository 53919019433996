const karinLawRoutes = props => {
  return {
    defaultRequestParams: {},
    showRoutePath: Routes.karin_law_workflow_requirement_path,
    indexRoutePath: Routes.karin_law_workflow_requirements_path,
    newRoutePath: Routes.new_karin_law_workflow_requirement_path,
    createRoutePath: Routes.karin_law_workflow_requirements_path,
  }
}

const karinLawPublicRoutes = props => {
  return {
    defaultRequestParams: {
      customer_id: props?.currentCustomer?.hashid,
      process_id: props?.requirement?.process.hashid
    },
    showRoutePath: Routes.created_public_karin_law_workflow_requirement_path,
    // indexRoutePath: null,
    // newRoutePath: null,
    createRoutePath: Routes.public_karin_law_workflow_requirements_path
  }
}

const karinLawPortalRoutes = props => {
  return {
    defaultRequestParams: {
      tenant: props?.currentCustomer?.portal_configuration?.tenant,
      process_id: props?.requirement.process_id
    },
    showRoutePath: Routes.portal_karin_law_workflow_requirement_path,
    indexRoutePath: Routes.portal_karin_law_workflow_requirements_path,
    newRoutePath: Routes.new_portal_karin_law_workflow_requirement_path,
    createRoutePath: Routes.portal_karin_law_workflow_requirements_path,
  }
}

const abstractRoutes = props => {
  return {
    defaultRequestParams: { current_entity_type_id: props?.currentEntityType?.hashid },
    showRoutePath: Routes.abstract_workflow_requirement_path,
    indexRoutePath: Routes.abstract_workflow_requirements_path,
    newRoutePath: '',
    createRoutePath: Routes.abstract_workflow_requirements_path,
  }
}

const abstractPublicRoutes = props => {
  return {
    defaultRequestParams: {
      customer_id: props?.currentCustomer?.hashid,
      process_id: props?.requirement?.process.hashid
    },
    showRoutePath: Routes.created_public_abstract_workflow_requirement_path,
    // indexRoutePath: '',
    // newRoutePath: '',
    createRoutePath: Routes.public_abstract_workflow_requirements_path,
  }
}

const abstractPortalRoutes = props => {
  return {
    defaultRequestParams: {
      tenant: props?.currentCustomer?.portal_configuration?.tenant,
      process_id: props?.requirement.process_id
    },
    showRoutePath: Routes.portal_abstract_workflow_requirement_path,
    indexRoutePath: Routes.portal_abstract_workflow_requirements_path,
    newRoutePath: Routes.new_portal_abstract_workflow_requirement_path,
    createRoutePath: Routes.portal_abstract_workflow_requirements_path,
  }
}

const economicCrimeRoutes = props => {
  return {
    defaultRequestParams: {},
    showRoutePath: Routes.economic_crime_workflow_requirement_path,
    indexRoutePath: Routes.economic_crime_workflow_requirements_path,
    newRoutePath: Routes.new_economic_crime_workflow_requirement_path,
    createRoutePath: Routes.economic_crime_workflow_requirements_path,
  }
}

const economicCrimePublicRoutes = props => {
  return {
    defaultRequestParams: {
      customer_id: props?.currentCustomer?.hashid,
      process_id: props?.requirement?.process.hashid
    },
    showRoutePath: Routes.created_public_economic_crime_workflow_requirement_path,
    // indexRoutePath: null,
    // newRoutePath: null,
    createRoutePath: Routes.public_economic_crime_workflow_requirements_path
  }
}

const economicCrimePortalRoutes = props => {
  return {
    defaultRequestParams: {
      tenant: props?.currentCustomer?.portal_configuration?.tenant,
      process_id: props?.requirement.process_id
    },
    showRoutePath: Routes.portal_economic_crime_workflow_requirement_path,
    indexRoutePath: Routes.portal_economic_crime_workflow_requirements_path,
    newRoutePath: Routes.new_portal_economic_crime_workflow_requirement_path,
    createRoutePath: Routes.portal_economic_crime_workflow_requirements_path,
  }
}


export const  workflowRequirementRoutesForm = props => {
  const context = props?.configuration?.context;

  let moduleType = ''

  switch(props?.requirement?.module_type){
    case 'KarinLaw::Workflow::Requirement':
      moduleType = 'karin_law'; break;
    case 'EconomicCrime::Workflow::Requirement':
      moduleType = 'economic_crime'; break;
    case 'Abstract::Workflow::Requirement':
      moduleType = 'abstract'; break;
  }

  const stringFunction = _.camelCase(
    _.join(
      _.concat(moduleType, context, 'routes'), '_'
    )
  )

  const options = eval(`${ stringFunction }(props)`)

  return {
    configuration: {
      defaultRequestParams: options.defaultRequestParams
    },
    routes: {
      createRoutePath: options.createRoutePath,
      indexRoutePath: options.indexRoutePath,
      newRoutePath: options.newRoutePath,
      showRoutePath: options.showRoutePath
    }
  }
}


