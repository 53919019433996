import React, {
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';

import {
  FormGroup,
  Button,
  Form,
  Spinner,
  Input,
  Label
} from 'reactstrap';

import I18n from 'i18n-js';

import {
  inputId,
  inputName,
  clearInputFiles
} from '../../helper/form';

import {
  nextStages as nextStagesHelper,
  returnStages as returnStagesHelper,
  isInputStage as isInputStageHelper,
  isDefaultStage as isDefaultStageHelper,
  isOutputStage as isOutputStageHelper,
  isShortlistStage as isShortlistStageHelper
} from '../requirement_stage/helper';

import FieldError from '../../helper/FieldError';

import KarinLawWorkflowRequirementAttributes from '../../karin_law/workflow/requirement/attributes';
import EconomicCrimeWorkflowRequirementAttributes from '../../economic_crime/workflow/requirement/attributes';
import WorkflowRequirementStageAttributes from '../requirement_stage/attributes';

import WorkflowRequirementStageToStageAttributes from '../requirement_stage/to_stage_attributes'

import WorkflowRequirementStageFormContext from '../requirement_stage/context/form';

const WorkflowRequirementAttributes = props => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    requirement,
    configuration: {
      formName = "workflow_requirement",
      enableCustomIdentifier = false,
      defaultRequestParams
    },
    routes: {
      indexRequirementsPath,
      showRequirementPath
    },
    services: {
      createRequirement: createRequirementService
    },
    callbacks: {
      onSetRequirement
    }
  } = props;

  const requirementStageType = useMemo(() => {
    if(requirement.current_requirement_stage.module_type == 'KarinLaw::Workflow::RequirementStage'){
      return 'karinLaw'
    } else if(requirement.current_requirement_stage.module_type == 'EconomicCrime::Workflow::RequirementStage'){
      return 'economicCrime'
    }
  }, [])

  const nextStages = useMemo(() => {
    return nextStagesHelper(requirement.current_requirement_stage)
  }, [requirement.current_requirement_stage.next_stages]);

  const returnStages = useMemo(() => {
    return returnStagesHelper(requirement.current_requirement_stage)
  }, [requirement.current_requirement_stage.return_stages])

  const isInputStage = useMemo(() => {
    return isInputStageHelper(requirement.current_requirement_stage);
  }, [requirement.current_requirement_stage.node_type])

  const isDefaultStage = useMemo(() => {
    return isDefaultStageHelper(requirement.current_requirement_stage);
  }, [requirement.current_requirement_stage.node_type])

  const isOutputStage = useMemo(() => {
    return isOutputStageHelper(requirement.current_requirement_stage);
  }, [requirement.current_requirement_stage.node_type])

  const isShortlistStage = useMemo(() => {
    return isShortlistStageHelper(requirement.current_requirement_stage);
  }, [requirement.current_requirement_stage.node_type])

  const RequirementFormComponent = useMemo(() => {
    if(requirementStageType == 'karinLaw'){
      return KarinLawWorkflowRequirementAttributes
    } else if(requirementStageType == 'economicCrime'){
      return EconomicCrimeWorkflowRequirementAttributes
    }
  }, [requirement.current_requirement_stage.module_type])

  useEffect(() => {
    // Se setea por defecto el aasm_state_event a 'to_proceeded' cuando es la primera etapa

    if(isInputStage){{
      onSetRequirement(prevState => {
        return {
          ...prevState,
          current_requirement_stage: {
            ... prevState.current_requirement_stage,
            aasm_state_event: 'to_proceeded',
            to_stage_id: nextStages[0]?.id
          }
        }
      })
    }}
  }, [])

  // ---------- START CALLBACKS ----------
  const onChangeRequirement = (event, key) => {
    let value = event.target.value;

    onSetRequirement(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  const onChangeRequirementStage = (event, key) => {
    const value = event.target.value;

    let currentRequirementStage = {
      ... requirement.current_requirement_stage,
      [key]: value
    }

    onChangeRequirement(
      { target: { value: currentRequirementStage }},
      'current_requirement_stage'
    )
  }
  // ---------- END CALLBACKS ----------

  // ----------------------------------------

  // ---------- START IDENTIFIER ----------
  const customIdentifierContainer = () => {
    if(enableCustomIdentifier){
      return(
        <div className="row">
          <div className="col-12">
            <div className="card card-material">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                    <div className="font-weight-bold">
                      { I18n.t('activerecord.attributes.workflow/requirement.identifier') }
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  { identifierInput() }
                </div>
              </div>
          </div>
        </div>
      )
    }
  }
  const identifierInput = () => {
    return(
      <FieldError errors={ _.get(requirement, 'errors.identifier') || [] }>
        <Input
          onChange={ e => onChangeRequirement(e, 'identifier') }
          value={ requirement.identifier || '' }
          id={ inputId(formName, 'identifier') }
          name={ inputName(formName, 'identifier') }
          invalid={ _.has(requirement, 'errors.identifier') }
        />
        <span className="text-muted small">
          { I18n.t('workflow.requirements.attributes.identifier.help') }
        </span>
      </FieldError>
    )
  }

  // ---------- END IDENTIFIER ----------

  // ----------------------------------------

  // ---------- START SUBMIT ----------
  const onConfirmSubmit = (event) => {
    event.preventDefault();
    swalWithBootstrap.fire({
      title: I18n.t('workflow.requirements.form.confirm.title'),
      html: I18n.t('workflow.requirements.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    })
  }

  const onSuccessSubmit = useCallback((_requirement) => {
    let requestParams = {
      ...defaultRequestParams,
      id: _requirement.hashid,
      _options: true,
      format: ''
    }

    if(_requirement.token){
      requestParams = { ... requestParams, token: _requirement.token }
    }

    window.location = showRequirementPath(requestParams)
  }, [])

  const onSubmit = () => {
    setLoadingSubmit(true)
    const formData = requirementFormData();

    createRequirementService(defaultRequestParams, formData, response => {
      if(response.status == 201){
        onSuccessSubmit(response.data)
      } else {
        clearInputFiles()
        onSetRequirement(response.data);
        setLoadingSubmit(false)
      }
    })
  }

  const requirementFormData = () => {
    let _formData = new FormData();

    _formData.append(`${ formName }[process_id]`, requirement.process_id || '');
    _formData.append(`${ formName }[accept_disclaimer]`, requirement.accept_disclaimer ? 1 : 0);
    _formData.append(`${ formName }[identifier]`, requirement.identifier || '');

    //  SI ES LEY KARIN

    if(requirementStageType == 'karinLaw'){
      _formData.append(`${ formName }[reported_by]`, requirement.reported_by || '');

      _formData.append(`${ formName }[victim_name]`, requirement.victim_name || '');
      _formData.append(`${ formName }[victim_identification_number]`, requirement.victim_identification_number || '');
      _formData.append(`${ formName }[victim_email]`, requirement.victim_email || '');

      _formData.append(`${ formName }[representative_name]`, requirement.representative_name || '');
      _formData.append(`${ formName }[representative_identification_number]`, requirement.representative_identification_number || '');
      _formData.append(`${ formName }[representative_email]`, requirement.representative_email || '');

      _formData.append(`${ formName }[perpetrator_name]`, requirement.perpetrator_name || '');
      _formData.append(`${ formName }[perpetrator_email]`, requirement.perpetrator_email || '');
    } else if(requirementStageType == 'economicCrime'){
      _formData.append(`${ formName }[complaint_type]`, requirement.complaint_type || '');
      _formData.append(`${ formName }[informer_identification_type]`, requirement.informer_identification_type || '');
      _formData.append(`${ formName }[informer_identification_number]`, requirement.informer_identification_number || '');
      _formData.append(`${ formName }[informer_name]`, requirement.informer_name || '');
      _formData.append(`${ formName }[informer_email]`, requirement.informer_email || '');
    }

    // CurrentRequirementStage
    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`
    const currentRequirementStage = requirement.current_requirement_stage;

    _formData.append(`${ currentRequirementStageFormName }[id]`, currentRequirementStage.id || '');
    _formData.append(`${ currentRequirementStageFormName }[current_stage_id]`, currentRequirementStage.current_stage_id || '');
    _formData.append(`${ currentRequirementStageFormName }[observations]`, currentRequirementStage.observations || '');

    if(currentRequirementStage.aasm_state_event == 'to_proceeded' || currentRequirementStage.aasm_state_event == 'to_returned'){
      _formData.append(`${ currentRequirementStageFormName }[to_stage_id]`, currentRequirementStage.to_stage_id || '');
    } else {
      _formData.append(`${ currentRequirementStageFormName }[to_stage_id]`, '');
    }

    if((currentRequirementStage.to_stage_authorized_emails || []).length > 0){
      _.each(currentRequirementStage.to_stage_authorized_emails, email => {
        _formData.append(`${ currentRequirementStageFormName }[to_stage_authorized_emails][]`, email)
      })
    }


    if(currentRequirementStage.node_type == 'input'){
      _formData.append(`${ currentRequirementStageFormName }[aasm_state_event]`, 'to_proceeded');
    } else {
      _formData.append(`${ currentRequirementStageFormName }[aasm_state_event]`, currentRequirementStage.aasm_state_event || '');
    }

    _formData.append(`${ currentRequirementStageFormName }[output_form_attributes][id]`, currentRequirementStage.output_form.id || '')

    // START OUTPUT FIELD ITEMS
    const outputFieldItemFormName = `${ currentRequirementStageFormName }[output_form_attributes][field_items_attributes]`;
    const outputFieldItems = currentRequirementStage.output_form.field_items || [];
    _.each(outputFieldItems, (fieldItem, index) => {
      _formData.append(`${ outputFieldItemFormName }[${ index }][id]`, fieldItem?.id || '');
      _formData.append(`${ outputFieldItemFormName }[${ index }][item_id]`, fieldItem.item_id || '');
      _formData.append(`${ outputFieldItemFormName }[${ index }][data_type]`, fieldItem.data_type || 'text');
      _formData.append(`${ outputFieldItemFormName }[${ index }][is_required]`, fieldItem.is_required || false );
      _formData.append(`${ outputFieldItemFormName }[${ index }][name]`, fieldItem.name || '' );
      _formData.append(`${ outputFieldItemFormName }[${ index }][value]`, fieldItem?.value || '');
      _formData.append(`${ outputFieldItemFormName }[${ index }][group_name]`, fieldItem?.group_name || '');
      _formData.append(`${ outputFieldItemFormName }[${ index }][helping_text]`, fieldItem?.helping_text || '' );
    });
    // END OUTPUT FIELD ITEMS

    // START OUTPUT FILE ITEMS
    const outputFileItemFormName = `${ currentRequirementStageFormName }[output_form_attributes][file_items_attributes]`;
    const outputFileItems = currentRequirementStage.output_form.file_items || [];
    _.each(outputFileItems, (fileItem, index) => {
      _formData.append(`${ outputFileItemFormName }[${ index }][id]`, fileItem?.id || '');
      _formData.append(`${ outputFileItemFormName }[${ index }][item_id]`, fileItem?.item_id || '');
      _formData.append(`${ outputFileItemFormName }[${ index }][value]`, fileItem?.value || '');
      _formData.append(`${ outputFileItemFormName }[${ index }][data_type]`, fileItem.data_type || 'text');
      _formData.append(`${ outputFileItemFormName }[${ index }][is_required]`, fileItem.is_required || false );
      _formData.append(`${ outputFileItemFormName }[${ index }][name]`, fileItem.name || '' );
      _formData.append(`${ outputFileItemFormName }[${ index }][helping_text]`, fileItem.helping_text || '' );
      _formData.append(`${ outputFileItemFormName }[${ index }][group_name]`, fileItem?.group_name || '' );

      if(fileItem.file){
        _formData.append(`${ outputFileItemFormName }[${ index }][file]`, fileItem?.file || '');
      }
    })
    // END OUTPUT FILE ITEMS

    return _formData;
  }
  // ---------- END SUBMIT ----------

  const backButton = useMemo(() => {
    if(!requirement.is_public){
      return(
        <a
          href={ indexRequirementsPath(defaultRequestParams) }
          className='btn btn-default'
        >
          { I18n.t('actions.back') }
        </a>
      )
    }
  });

  const assignResponsible = useMemo(() => {
    return(
      props?.configuration?.context != 'portal' &&
      props?.configuration?.context != 'public' &&
      requirement.snapshot_process.assign_responsible_on_initial_stage
    )
  }, []);

  const workflowRequirementStageToStageAttributes = () => {
    if(assignResponsible){
      return(
        <div className="row">
          <div className="col-12">
            <WorkflowRequirementStageToStageAttributes
              requirementStage= { requirement.current_requirement_stage }
              configuration={{ formName: `${ formName }[current_requirement_stage_attributes]` } }
            />
          </div>
        </div>
      )

    }
  }

  return(
    <WorkflowRequirementStageFormContext.Provider
      value={{
        requirementStageType: requirementStageType,
        data: {
          nextStages,
          returnStages
        },
        helpers: {
          isInputStage: isInputStage,
          isDefaultStage: isDefaultStage,
          isOutputStage: isOutputStage
        },
        callbacks: {
          onChangeRequirementStage: onChangeRequirementStage
        }
      }}
    >
      <Form onSubmit={ event => onConfirmSubmit(event) }>
        <div className="row">
          <div className="col-12">
            { workflowRequirementStageToStageAttributes() }

            { customIdentifierContainer() }

            { function(){
              if(RequirementFormComponent){
                return(
                  <RequirementFormComponent
                    requirement={ requirement }
                    configuration={ props?.configuration }
                    routes={ props?.routes }
                    data={ props?.data }
                    callbacks={{
                      onSetRequirement: onSetRequirement,
                      onChangeRequirementStage: onChangeRequirementStage
                    }}
                  />
                )
              }
            }()}

            <WorkflowRequirementStageAttributes
              requirementStage={ requirement.current_requirement_stage }
              configuration={{
                formName: formName
              }}
              callbacks={{
                onChangeRequirementStage: onChangeRequirementStage
              }}
            />
          </div>
          <div className="col-12">
            <FormGroup className='text-end'>
              { backButton }
              <Button
                color='success'
                className='ml-2'
                type='submit'
                disabled={loadingSubmit}
              >
                {loadingSubmit ? (
                  <>
                    <Spinner
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2"
                    />
                    <span>{I18n.t('actions.sending')}</span>
                  </>
                ) : (
                  I18n.t('actions.send')
                )}
              </Button>
            </FormGroup>
          </div>
        </div>
      </Form>
    </WorkflowRequirementStageFormContext.Provider>
  )
}

export default WorkflowRequirementAttributes

