import React from 'react';
import Select from 'react-select'
import * as XLSX from 'xlsx';
import { Input, Button, Form, FormGroup } from 'reactstrap';
import { useState } from 'react';
import I18n from 'i18n-js';
import ComplianceBulkLoadActivitiesFilePreview from './filePreview';


const ComplianceBulkLoadActivitiesForm = (props) => {
  const { activityRequiredFields } = props;
  const [fileData, setFileData] = useState({
    sampleRows: [],
    headers: [],
    totalRows: 0,
    filePresent: false
  })

  const onUploadFile = (target) => {
    let file = target;
    let isXlsx = file.value.split("\\").pop().includes(".xlsx");

    if (file != "" && file.type == "file" && isXlsx) {
      setFileData(
        prevFileData => ({
          ...prevFileData,
          filePresent: true
        })
      );
      let reader = new FileReader();
      reader.readAsArrayBuffer(file.files[0]);
      reader.onloadend = (e) => {
        let newData = new Uint8Array(e.target.result);
        let workbook = XLSX.read(newData, { type: "array" });
        let firstSheet = workbook.SheetNames[0];
        let sheet = workbook.Sheets[firstSheet]['!ref'];
        let range = XLSX.utils.decode_range(sheet);
        let excelInJSON = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet], { range: range, blankRows: true, defval: '' });
        let headersFile = _.keys(excelInJSON[0])

        let firstThreeRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet], {
          range: { s: { r: 0, c: 0 }, e: { r: 3, c: range.e.c } },
          blankRows: true,
          defval: '',
          raw: false
        });

        setFileData(
          prevFileData => ({
            ...prevFileData,
            sampleRows: firstThreeRows,
            headers: headersFile,
            totalRows: range.e.r + 1
          })
        );
      }
    } else {
      setFileData(
        prevFileData => ({
          ...prevFileData,
          filePresent: false
        })
      );
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'El documento debe ser formato excel',
      })
    }
  }

  const submitButton = () => {
    return (
      <Button
        type='submit'
        className='btn btn-success'
      >
        {I18n.t('actions.save')}
      </Button>
    )
  }

  const fileInput = () => {
    return (
      <FormGroup>
        <Input
          type="file"
          name={`[compliance_bulk_load_activity][file]`}
          id="compliance_bulk_load_activities_file"
          placeholder={I18n.t("compliance.bulk.load.activities.form.file_placeholder")}
          onChange={(e) => onUploadFile(e.target)}
          required
        />
      </FormGroup>
    )
  }

  const activityFields = () => {
    const fields = [];

    _.each(activityRequiredFields, (field, index) => {
      let fieldTranslationKey = `activerecord.attributes.compliance/activity.${field}`;
      const helpTranslationKey = `activerecord.help.compliance/activity.${field}`;

      if (field === 'recurrence') {
        fieldTranslationKey += '.one';
      }

      fields.push(
        <tr key={index}>
          <td>
            {I18n.t(fieldTranslationKey)}
            <small className="form-text text-muted">{I18n.t(helpTranslationKey)}</small>
          </td>
          <td>
            <FormGroup>
              <Select
                name={`[compliance_bulk_load_activity][columns_detail][${field}]`}
                isDisabled={fileData.headers.length === 0}
                placeholder={I18n.t("compliance.bulk.load.activities.form.select_placeholder")}
                options={_.map(fileData.headers, (item) => ({ value: item, label: item }))}
                required
              />
            </FormGroup>
          </td>
        </tr>
      );
    });

    return <>{fields}</>;
  };

  return (
    <div className="container">
      <Form action={Routes.compliance_bulk_load_activities_path()} encType="multipart/form-data" method="post" id="new_laboral_bulk_load_employee">
        <input type="hidden" name="authenticity_token" value={window.compliance.token} />
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header fw-bold">
                {I18n.t("compliance.bulk.load.activities.form.file_placeholder")}
              </div>
              <div className="card-body">
                {fileInput()}
              </div>
              <ComplianceBulkLoadActivitiesFilePreview
                fileData={fileData}
              />
            </div>

            <div className="card">
              <span className='p-2'>{I18n.t("compliance.bulk.load.activities.form.help")}</span>
              <div className="card-body">
                <table className="table table table-borderless bg-white rounded border-0">
                  <thead className='card-header'>
                    <tr>
                      <th scope="col" className='w-50'>{I18n.t("compliance.bulk.load.activities.form.activity_field")}</th>
                      <th scope="col">{I18n.t("compliance.bulk.load.activities.form.file_field")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityFields()}
                    <tr key="reviewer_groups">
                      <td>
                        {I18n.t("activerecord.models.compliance/reviewer_group.other")}
                        <small className="form-text text-muted">
                          {I18n.t("compliance.reviewer_groups.bulk.load.field_help")}
                        </small>
                      </td>
                      <td>
                        <FormGroup>
                          <Select
                            name="compliance_bulk_load_activity[columns_detail][reviewer_groups]"
                            isDisabled={fileData.headers.length === 0}
                            placeholder={I18n.t("compliance.bulk.load.activities.form.select_placeholder")}
                            options={_.map(fileData.headers, (item) => ({ value: item, label: item }))}
                            required
                          />
                        </FormGroup>
                      </td>
                    </tr>
                    <tr key="uploader_groups">
                      <td>
                        {I18n.t("activerecord.models.compliance/uploader_group.other")}
                        <small className="form-text text-muted">{I18n.t("compliance.uploader_groups.bulk.load.field_help")}</small>
                      </td>
                      <td>
                        <FormGroup>
                          <Select
                            name="compliance_bulk_load_activity][columns_detail][uploader_groups]"
                            isDisabled={fileData.headers.length === 0}
                            placeholder={I18n.t("compliance.bulk.load.activities.form.select_placeholder")}
                            options={_.map(fileData.headers, (item) => ({ value: item, label: item }))}
                            required
                          />
                        </FormGroup>
                      </td>
                    </tr>
                    <tr key="evidences">
                      <td>
                        {I18n.t("activerecord.models.compliance/evidence.other")}
                        <small className="form-text text-muted">{I18n.t("activerecord.help.compliance/activity.evidences")}</small>
                      </td>
                      <td>
                        <FormGroup>
                          <Select
                            name="compliance_bulk_load_activity][columns_detail][evidences]"
                            isDisabled={fileData.headers.length === 0}
                            placeholder={I18n.t("compliance.bulk.load.activities.form.select_placeholder")}
                            options={_.map(fileData.headers, (item) => ({ value: item, label: item }))}
                            required
                          />
                        </FormGroup>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-end">
              {submitButton()}
            </div>
          </div>
        </div>
      </Form >
    </div >
  );
};

export default ComplianceBulkLoadActivitiesForm;
