import React, {
  useMemo,
  useEffect
} from 'react';

import {
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import Select from 'react-select';

import FieldError from '../../helper/FieldError';

import {
  inputId,
  inputName,
  drawTooltipHelp
} from '../../helper/form';

import {
  fieldItemIsRequired as isRequired,
  fieldItemHtmlInputType as htmlInputType
} from '../requirement_stage/helper';

const WorkflowRequirementStageFormItemFieldAttributes = props => {
  const {
    requirementStage,
    fieldItem,
    configuration: {
      formName
    },
    callbacks: {
      onChangeRequirementStage
    }
  } = props;

  const onChangeFieldItem = (event, key) => {
    let _fieldItem = { ... fieldItem };
    let value = event.target.value;

    _fieldItem[key] = value;

    let outputForm = requirementStage.output_form;

    const fieldItemIndex = _.findIndex(outputForm.field_items, item => {
      return item.item.id == fieldItem.item.id
    })

    outputForm.field_items[fieldItemIndex] = _fieldItem;

    onChangeRequirementStage(
      { target: { value: outputForm }}, 'output_form'
    )
  }

  const helpingText = () => {
    if(fieldItem.helping_text){
      return (
        <span className='text-muted small'>{ fieldItem.helping_text }</span>
      )
    }
  }

  const inputOrSelectFieldItem = () => {
    if(fieldItem.data_type == "list"){
      return(
        <Select
          options={ _.map(fieldItem.item.list_options, option=>{ return { label: option, value: option }}) }
          onChange={ e => onChangeFieldItem({target:{ value: e.value }}, 'value') }
          value={ { label: fieldItem?.value, value: fieldItem?.value } }
          id={ inputId(formName, 'value') }
          name={ inputName(formName, 'value') }
          invalid={ _.has(fieldItem.errors, 'value') }
        />
      )
    }else{
      return(
        <Input
          type={ htmlInputType(fieldItem) }
          required={ isRequired(fieldItem) }
          onChange={ e => onChangeFieldItem(e, 'value') }
          value={ fieldItem?.value || '' }
          invalid={ _.has(fieldItem.errors, 'value') }
          id={ inputId(formName, 'value') }
          name={ inputName(formName, 'value') }
        />
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <FieldError errors={ _.get(fieldItem, 'errors.value') || [] }>
            <Label
              className={ isRequired(fieldItem) ? 'required' : ''}
              htmlFor={ inputId(formName, 'value') }
            >
              { fieldItem.name }
            </Label>
            { inputOrSelectFieldItem() }

            { helpingText() }
          </FieldError>
        </FormGroup>
      </div>
    </div>
  )
}

export default React.memo(WorkflowRequirementStageFormItemFieldAttributes);
