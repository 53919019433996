import React, {
  useMemo,
  useEffect,
  useState
} from 'react'

import axios from "axios";

import I18n from 'i18n-js';

import {
  FormGroup,
  Input,
} from 'reactstrap';

import WorkflowRequirementAttributes from './attributes';
import KarinLawWorkflowRequirementAttributes from '../../karin_law/workflow/requirement/attributes';
import EconomicCrimeWorkflowRequirementAttributes from '../../economic_crime/workflow/requirement/attributes';

import WorkflowRequirementDisclaimer from './disclaimer';

import { workflowRequirementRoutesForm } from './routes.js'

const WorkflowRequirementForm = props => {
  const {
    currentUser,
  } = props;

  const [requirement, setRequirement] = useState(props?.requirement);

  const {
    routes,
  } = workflowRequirementRoutesForm(props)

  const configuration = {
    ...workflowRequirementRoutesForm(props).configuration,
    ...props?.configuration,
    formName: 'workflow_requirement',
  }

  const { formName } = configuration;

  // -------------------- START CALLBACKS --------------------
  const createService = (requestParams, formData, callback) => {
    let promise = axios({
      method: 'post',
      url: routes.createRoutePath({...{...requestParams}, format: 'json', _options: true }),
      data: formData,
      headers: {
        "X-CSRF-TOKEN": window.compliance.token,
      },
    });

    promise
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error.response);
      });
  }
  // -------------------- END CALLBACKS --------------------

  // -------------------- START MEMO --------------------
  const requireAcceptDisclaimer = useMemo(() => {
    return requirement.snapshot_process.require_disclaimer && !requirement.accept_disclaimer
  }, [requirement.accept_disclaimer])

   const isKarinLaw = useMemo(() => {
    return requirement.module_type == 'KarinLaw::Workflow::Requirement'
  }, [])

  const isAbstract = useMemo(() => {
    return requirement.module_type == 'Abstract::Workflow::Requirement'
  }, [])

  const isEconomicCrime = useMemo(() => {
    return requirement.module_type == 'EconomicCrime::Workflow::Requirement';
  })

  const isPublicContext = useMemo(() => {
    return props?.configuration?.context == 'public'
  }, [])

  const isPortalContext = useMemo(() => {
    return props?.configuration?.context == 'portal'
  }, [])
  // -------------------- END MEMO --------------------


  useEffect(() => {
    if(!requirement.process_id){
      window.location = routes.newRoutePath(configuration.defaultRequestParams)
    }
  }, [])

  const FormComponent = useMemo(() => {
    if(requireAcceptDisclaimer){
      return WorkflowRequirementDisclaimer
    } else {
      return WorkflowRequirementAttributes
    }
  }, [requirement.accept_disclaimer])

  const enableCustomIdentifier = useMemo(() => {
    return (
      requirement.snapshot_process.require_custom_identifier &&
      (
        (
          isPortalContext && _.includes(requirement.current_requirement_stage.authorized_emails, currentUser?.email) ||
          (!isPortalContext && !isPublicContext)
        )
      )
    )
  }, [])

  return(
    <div className="row">
      <div className="col-12">
        <FormComponent
          requirement={ requirement }
          configuration={{
            ...configuration, enableCustomIdentifier
          }}
          routes={{
            indexRequirementsPath: routes.indexRoutePath,
            showRequirementPath: routes.showRoutePath
          }}
          services={{
            createRequirement: createService,
          }}
          callbacks={{
            onSetRequirement: setRequirement
          }}
          data={ props.data }
        />
      </div>
    </div>
  )
}

export default WorkflowRequirementForm;
