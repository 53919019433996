import React, {
  useMemo,
  useCallback,
  useContext
} from 'react';

import {
  FormGroup,
  Input,
  Label
} from 'reactstrap';

import I18n from 'i18n-js';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import FieldError from '../../helper/FieldError';

import {
  inputId,
  inputName,
} from '../../helper/form';

import WorkflowRequirementStageFormAttributes from '../requirement_stage_form/attributes'

import WorkflowRequirementStageFormContext from '../../workflow/requirement_stage/context/form';

const WorkflowRequirementStageAttributes = props => {

  const {
    requirementStage,
    actions,
    callbacks,
    configuration: {
      formName,
      defaultRequestParams,
    },
  } = props;

  const {
    data: {
      nextStages,
      returnStages
    },
    helpers: {
      isInputStage,
      isDefaultStage,
      isOutputStage,
    },
    callbacks: {
      onChangeRequirementStage: callbackOnChangeRequirementStage
    }
  } = useContext(WorkflowRequirementStageFormContext)

  // ----------------------------------------

  // ---------- START OBSERVATIONS ----------
  const observationsInput = () => {
    if(requirementStage.aasm_state_event == 'to_rejected' || requirementStage.aasm_state_event == 'to_returned'){
      return(
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <FieldError errors={ _.get(requirementStage, 'errors.observations') || [] }>
                <Label
                  className={ 'required' }
                  htmlFor={ inputId(formName, 'observations') }
                >
                  { I18n.t('activerecord.attributes.workflow/requirement_stage.observations') }
                </Label>
                <Input
                  type={ 'textarea' }
                  required={ true }
                  onChange={ e => callbackOnChangeRequirementStage(e, 'observations') }
                  value={ requirementStage.observations || '' }
                  id={ inputId(formName, 'observations') }
                  name={ inputName(formName, 'observations') }
                  invalid={ _.has(requirementStage, 'errors.observations') }
                />
              </FieldError>
            </FormGroup>
          </div>
        </div>
      )
    }
  }
  // ---------- END OBSERVATIONS ----------
  // ----------------------------------------

  const showWorkflowRequirementStageFormAttributesComponent = useMemo(() => {
    return _.includes(['to_proceeded', 'to_completed'], requirementStage.aasm_state_event) || requirementStage.aasm_state_event == null
  }, [requirementStage.aasm_state_event])

  const workflowRequirementStageFormAttributesComponent = () => {
    if(showWorkflowRequirementStageFormAttributesComponent){
      return(
        <WorkflowRequirementStageFormAttributes
          requirementStage={ requirementStage }
          actions={ actions }
          stageForm={ requirementStage.output_form }
          configuration={{ ... props.configuration, formName: `${ formName }[output_form_attributes]`, formType: 'output_form', defaultRequestParams: defaultRequestParams }}
          callbacks={ callbacks }
        />
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        { workflowRequirementStageFormAttributesComponent() }
        { observationsInput() }
      </div>
    </div>
  )
}

export default WorkflowRequirementStageAttributes;
