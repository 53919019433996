import React, {
  useState,
  useMemo,
  useCallback
} from 'react';

import {
  FormGroup,
  Button,
  Form,
  Label,
  Input
} from 'reactstrap';

import I18n from 'i18n-js';

import Select from 'react-select';

import FieldError from '../../../helper/FieldError';

import {
  inputId,
  inputName,
  clearInputFiles
} from '../../../helper/form';

import {
  fieldItemIsRequired,
  fieldItemHtmlInputType
} from '../../../workflow/requirement_stage/helper'

import WorkflowRequirementStageAttributes from '../../../workflow/requirement_stage/attributes';
import WorkflowRequirementStageFormItemFieldAttributes from '../../../workflow/requirement_stage_form_item/field_attributes';
import WorkflowRequirementStageFormItemFileAttributes from '../../../workflow/requirement_stage_form_item/file_attributes';

const EconomicCrimeWorkflowRequirementAttributes = props => {

  const {
    requirement,
    configuration: {
      formName = 'workflow_requirement',
      defaultRequestParams
    },
    routes: {
      indexRequirementsPath,
      showRequirementPath
    },
    callbacks: {
      onSetRequirement,
      onChangeRequirementStage
    },
    data: {
      economicCrime: {
        complaintTypes,
        informer: {
          identificationTypes: informerIdentificationTypes
        }
      }
    }
  } = props;

  // ---------- START OUTPUT FIELDS ----------

  const outputFieldItemByGroupName = useCallback((groupName) => {
    return _.filter(requirement.current_requirement_stage.output_form.field_items, fieldItem => {
      return fieldItem.group_name == groupName
    })
  }, [requirement.current_requirement_stage.output_form.field_items])

  const outputFieldItemAttributes = groupName => {
    let fieldItems = outputFieldItemByGroupName(groupName)

    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`

    return _.map(fieldItems, (fieldItem, index) => {
      return(
        <WorkflowRequirementStageFormItemFieldAttributes
          key={ `WorkflowRequirementStageFormItemFieldAttributes-${ fieldItem.item.id }` }
          requirementStage={ requirement.current_requirement_stage }
          fieldItem={ fieldItem }
          configuration={{ ... props.configuration, formName: `${ formName }[current_requirement_stage_attributes][output_form_attributes][field_items_attributes][${ fieldItem.item.id }]`, index: index}}
          callbacks={ props?.callbacks }
        />
      )
    })
  }
  // ---------- END OUTPUT FIELDS ----------

  // ---------- START OUTPUT FILES ----------

  const outputFileItemByGroupName = useCallback((groupName) => {
    return _.filter(requirement.current_requirement_stage.output_form.file_items, fileItem => {
      return fileItem.group_name == groupName
    })
  }, [requirement.current_requirement_stage.output_form.file_items])

  const outputFileItemAttributes = groupName => {
    let fileItems = outputFileItemByGroupName(groupName)

    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`

    return _.map(fileItems, (fileItem, index) => {
      return(
        <WorkflowRequirementStageFormItemFileAttributes
          key={ `WorkflowRequirementStageFormItemFileAttributes-${ fileItem.item.id }` }
          requirementStage={ requirement.current_requirement_stage }
          fileItem={ fileItem }
          configuration={{ ... props.configuration, formName: `${ formName }[current_requirement_stage_attributes][output_form_attributes][file_items_attributes][${ fileItem.item.id }]` }}
          callbacks={ props?.callbacks }
        />
      )
    })
  }
  // ---------- END OUTPUT FIELDS ----------

  const onChangeRequirement = (event, key) => {
    const value = event?.target?.value;

    onSetRequirement(prevState => {
      return { ... prevState, [key]: value}
    })
  }

  // ---------- START COMPLAINT TYPE FIELDS ----------

  const complaintTypeIsAnonymous = useMemo(() => {
    return requirement.complaint_type == 'anonymous'
  }, [requirement.complaint_type])

  const complaintTypeIsIdentified = useMemo(() => {
    return requirement.complaint_type == 'identified'
  }, [requirement.complaint_type])

  const complaintTypeSelectOptions = useMemo(() => {
    return _.map(complaintTypes, complaintType => {
      return {
        value: complaintType,
        label: I18n.t(`activerecord.attributes.economic_crime/workflow/requirement.complaint_type.${ complaintType }`)
      }
    })
  }, [])

  const complaintTypeSelectedOption = useMemo(() => {
    if(requirement.complaint_type){
      return {
        value: requirement.complaint_type,
        label: I18n.t(`activerecord.attributes.economic_crime/workflow/requirement.complaint_type.${ requirement.complaint_type }`)
      }
    } else {
      return null
    }
  }, [requirement.complaint_type])

  const complaintTypeInputSelect = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.complaint_type') || [] }>
          <Select
            id={ inputId(formName, 'complaint_type') }
            name={ inputName(formName, 'complaint_type') }
            invalid={ _.has(requirement?.errors, 'complaint_type') }
            options={ complaintTypeSelectOptions }
            value={ complaintTypeSelectedOption }
            onChange={ e => onChangeRequirement({ target: { value: e?.value }}, 'complaint_type') }
            placeholder={ '-- Selecciona un tipo de denuncia --' }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const complaintTypeFields = () => {
    return(
      <div className="row">
        <div className="col-12">
          <div className="card card-material">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.complaint_type.one') }
                  </div>
                </div>
              </div>
              <div className="card-body">
                { complaintTypeInputSelect() }
              </div>
            </div>
        </div>
      </div>
    )
  }

  // ---------- END COMPLAINT TYPE FIELDS ----------

  // ---------- START INFORMER FIELDS ----------

  const informerIdentificationTypeSelectOptions = useMemo(() => {
    return _.map(informerIdentificationTypes, identificationType => {
      return {
        value: identificationType,
        label: I18n.t(`activerecord.attributes.economic_crime/workflow/requirement.informer_identification_type.${ identificationType }`)
      }
    })
  }, [])

  const informerIdentificationTypeSelectedOption = useMemo(() => {
    if(requirement.informer_identification_type){
      return {
        value: requirement.informer_identification_type,
        label: I18n.t(`activerecord.attributes.economic_crime/workflow/requirement.informer_identification_type.${ requirement.informer_identification_type }`)
      }
    } else {
      return null
    }
  }, [requirement.informer_identification_type])

  const informerIdentificationTypeInputSelect = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.informer_identification_type') || [] }>
          <Label
            className='required'
            htmlFor={ inputId(formName, 'informer_identification_type') }
          >
            { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.informer_identification_type.one') }
          </Label>
          <Select
            id={ inputId(formName, 'informer_identification_type') }
            name={ inputName(formName, 'informer_identification_type') }
            invalid={ _.has(requirement?.errors, 'informer_identification_type') }
            options={ informerIdentificationTypeSelectOptions }
            value={ informerIdentificationTypeSelectedOption }
            onChange={ e => onChangeRequirement({ target: { value: e?.value }}, 'informer_identification_type') }
            placeholder={ '-- Selecciona un tipo de identificación --' }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const informerIdentificationNumberInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.informer_identification_number') || [] }>
          <Label
            className='required'
            htmlFor={ inputId(formName, 'informer_identification_number') }
          >
            { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.informer_identification_number') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'informer_identification_number') }
            name={ inputName(formName, 'informer_identification_number') }
            invalid={ _.has(requirement?.errors, 'informer_identification_number') }
            value={ requirement.informer_identification_number || '' }
            onChange={ e => onChangeRequirement(e, 'informer_identification_number') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const informerNameInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.informer_name') || [] }>
          <Label
            className='required'
            htmlFor={ inputId(formName, 'informer_name') }
          >
            { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.informer_name') }
          </Label>

          <Input
            type='text'
            id={ inputId(formName, 'informer_name') }
            name={ inputName(formName, 'informer_name') }
            invalid={ _.has(requirement?.errors, 'informer_name') }
            value={ requirement.informer_name || '' }
            onChange={ e => onChangeRequirement(e, 'informer_name') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const informerIdentificationEmailInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ _.get(requirement, 'errors.informer_email') || [] }>
          <Label
            className='required'
            htmlFor={ inputId(formName, 'informer_email') }
          >
            { I18n.t('activerecord.attributes.economic_crime/workflow/requirement.informer_email') }
          </Label>

          <Input
            type='email'
            id={ inputId(formName, 'informer_email') }
            name={ inputName(formName, 'informer_email') }
            invalid={ _.has(requirement?.errors, 'informer_email') }
            value={ requirement.informer_email || '' }
            onChange={ e => onChangeRequirement(e, 'informer_email') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const informerFields = () => {

    const title = I18n.t('economic_crime.workflow.requirements.form.informer.title')

    if (complaintTypeIsIdentified){
      return(
        <div className="row">
          <div className="col-12">
            <div className="card card-material">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                    <div className="font-weight-bold">
                      { title }
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  { informerIdentificationTypeInputSelect() }
                  { informerIdentificationNumberInput() }
                  { informerNameInput() }
                  { informerIdentificationEmailInput() }
                  { outputFieldItemAttributes(title) }
                  { outputFileItemAttributes(title) }
                </div>
              </div>
          </div>
        </div>
      )
    }
  }

  // ---------- START INFORMER FIELDS ----------



  return(
    <div className="row">
      <div className="col-12">
        { complaintTypeFields() }
        { informerFields() }
      </div>
    </div>
  )
}

export default EconomicCrimeWorkflowRequirementAttributes;
